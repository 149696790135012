export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/_dbd": [22,[3]],
		"/_dbd/asset0": [25,[3]],
		"/_dbd/asset0/[action]": [~26,[3]],
		"/_dbd/asset": [~23,[3]],
		"/_dbd/asset/_tobe_del_edit/[[id]]": [~24,[3]],
		"/_dbd/login": [~27,[3]],
		"/_dbd/logout": [~28,[3]],
		"/_dbd/page": [~29,[3]],
		"/_dbd/page/edit/[[id]]": [~30,[3]],
		"/_dbd/post": [~31,[3]],
		"/_dbd/post/edit/[[entry_id]]": [~32,[3]],
		"/_dbd/tag": [~33,[3]],
		"/(raw)/editimg": [5],
		"/(raw)/floats/ref": [6],
		"/(raw)/floats/snapshot": [7],
		"/(web)/gallery": [15,[2]],
		"/(raw)/glb-viewer/[[id]]": [~8],
		"/(raw)/minpdf": [9],
		"/(raw)/minpdf/viewer": [10],
		"/(web)/page/[key]/[[params]]": [~16,[2]],
		"/(web)/post/[entry_id]": [~17,[2]],
		"/(web)/privacy-policy": [18,[2]],
		"/(web)/termsof-service": [19,[2]],
		"/(raw)/test-shader/0": [11],
		"/(raw)/test-shader/c": [12],
		"/(raw)/test-shader/gpt": [13],
		"/(web)/test": [~20,[2]],
		"/(web)/tryonline": [~21,[2]],
		"/(raw)/try": [14]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';